"use client";
import { useState } from "react";

export function useLocalStorage(key: string, initialValue?: string) {
  // Get the stored value from localStorage or use the initial value
  const [storedValue, setStoredValue] = useState<string | null>(() => {
    if (typeof window === "undefined") {
      return initialValue ?? null;
    }
    try {
      const item = localStorage.getItem(key);
      return item !== null ? item : initialValue ?? null;
    } catch (error) {
      console.error(`Error getting item from localStorage for key "${key}"`, error);
      return initialValue ?? null;
    }
  });

  // Update localStorage when the stored value changes
  const setValue = (value: string) => {
    try {
      setStoredValue(value);
      if (typeof window !== "undefined") {
        localStorage.setItem(key, value);
      }
    } catch (error) {
      console.error(`Error setting item to localStorage for key "${key}"`, error);
    }
  };

  return [storedValue, setValue] as const;
}
